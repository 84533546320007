import React from 'react';
import FAQSection from '../components/FAQSection';

function FAQ() {
  return (
    <main>
      <h1>Frequently Asked Questions</h1>
      <FAQSection />
    </main>
  );
}

export default FAQ;