import React from 'react';
import PricingSection from '../components/PricingSection';

function Pricing() {
  return (
    <main>
      <h1>Pricing</h1>
      <PricingSection />
    </main>
  );
}

export default Pricing;