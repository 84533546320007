import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-20">
      <h1 className="text-4xl font-bold mb-8">Directory Maker Privacy Policy</h1>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">1. Introduction</h2>
      <p className="mb-4">
        At Directory Maker, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our website and services. By accessing or using Directory Maker, you agree to the terms of this Privacy Policy.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">2. Information We Collect</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">Personal Information</h3>
      <p className="mb-4">
        When you purchase or use Directory Maker, we may collect personal information such as your name, email address, billing address, payment details, and any other information you provide directly to us.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">Technical Information</h3>
      <p className="mb-4">
        We may also collect non-personal information related to your interaction with our website and services, including your IP address, browser type, operating system, and access times. This data helps us improve our services and understand how users interact with our platform.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">Cookies and Tracking Technologies</h3>
      <p className="mb-4">
        Directory Maker uses cookies and similar tracking technologies to enhance your experience on our website. Cookies are small data files stored on your device that help us remember your preferences and understand how you use our site. You can control cookies through your browser settings, but disabling them may affect your experience on our site.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">3. How We Use Your Information</h2>
      <p className="mb-4">
        We use the information we collect for the following purposes:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>To Provide and Improve Our Services: We use your information to process transactions, provide customer support, and improve the functionality and user experience of Directory Maker.</li>
        <li>To Communicate with You: We may use your contact information to send you important updates, marketing communications, and other information related to Directory Maker. You can opt-out of marketing emails at any time by following the unsubscribe instructions in the email.</li>
        <li>To Comply with Legal Obligations: We may use your information to comply with applicable laws, regulations, and legal processes.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-8 mb-4">4. How We Share Your Information</h2>
      <p className="mb-4">
        We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted third parties who assist us in operating our website, conducting our business, or providing services to you, such as payment processors and hosting providers. These third parties are required to keep your information confidential and use it only for the purposes we specify.
      </p>
      <p className="mb-4">
        We may also disclose your information if required by law or in response to a valid legal request, such as a subpoena or court order.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">5. Data Security</h2>
      <p className="mb-4">
        We take data security seriously and implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. While we strive to protect your information, please note that no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">6. Your Rights and Choices</h2>
      <p className="mb-4">
        You have the following rights regarding your personal information:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li><strong>Access and Update:</strong> You have the right to access and update the personal information we hold about you. If you need to update your information, please contact us.</li>
        <li><strong>Opt-Out:</strong> You can opt-out of receiving marketing communications from us by following the unsubscribe instructions in the emails we send or by contacting us directly.</li>
        <li><strong>Data Deletion:</strong> You have the right to request the deletion of your personal information. Please note that we may retain certain information as required by law or for legitimate business purposes.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-8 mb-4">7. Third-Party Links</h2>
      <p className="mb-4">
        Directory Maker may contain links to third-party websites or services that are not operated by us. This Privacy Policy does not apply to those third-party websites, and we are not responsible for the content, privacy practices, or security of those sites. We encourage you to review the privacy policies of any third-party websites you visit.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">8. Children's Privacy</h2>
      <p className="mb-4">
        Directory Maker is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have inadvertently collected personal information from a child under 18, we will take steps to delete such information from our records.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">9. Changes to This Privacy Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the new Privacy Policy on our website and updating the effective date at the top of this document. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
      </p>
    </div>
  );
}

export default PrivacyPolicy;

