import React from 'react';
import FeaturesSection from '../components/FeaturesSection';

function Features() {
  return (
    <main>
      <h1>Directory Maker Features</h1>
      <FeaturesSection />
    </main>
  );
}

export default Features;